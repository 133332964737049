import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Block from '../../components/ui/Cards/Block';
import PostCard from '../../components/ui/Cards/PostCard/PostCard';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import { PostsSelector } from '../../components/utils/PostsSelector';
import Seo from '../../components/utils/Seo';
import { useAnimationOnScroll } from '../../components/utils/useAnimateOnScroll';
import { Direction } from '../../globals';
import './resources.scss';

interface ResourcesProps {
    data: {
        resourcesYaml: {
            resourcesBlogs: PostsPerCategory[];
        };
        allMdx: {
            nodes: PostDetails[];
        };
    };
}

const Resources: React.FC<ResourcesProps> = ({ data }: ResourcesProps) => {
    useAnimationOnScroll();
    const { posts, postsPerCategory } = new PostsSelector(
        data.allMdx.nodes
    ).getByCategory(data.resourcesYaml.resourcesBlogs);

    // const resourcesFilterer = new ResourcesFilterer(
    //     data.resourcesYaml.resources
    // );

    // const [resources, setResources] = useState(
    //     resourcesFilterer.groupByTopic()
    // );

    // const updateDashboard = (
    //     resourcesFilterer: ResourcesFilterer,
    //     groupField: ResourcesGroupField
    // ) => {
    //     switch (groupField) {
    //         case ResourcesGroupField.platform: {
    //             const newResources = resourcesFilterer.groupByPlatform();
    //             setResources(newResources);
    //             return;
    //         }
    //         case ResourcesGroupField.topic: {
    //             const newResources = resourcesFilterer.groupByTopic();
    //             setResources(newResources);
    //             return;
    //         }
    //         case ResourcesGroupField.type: {
    //             const newResources = resourcesFilterer.groupByType();
    //             setResources(newResources);
    //             return;
    //         }
    //     }
    // };

    // const renderDashboard = (resourcesMap: Record<string, Resource[]>) => {
    //     const renderedItems = [];

    //     const resourcesGroupFields = Object.keys(resourcesMap);

    //     for (let j = 0; j < Object.keys(resourcesMap).length; j++) {
    //         renderedItems.push(
    //             resourcesGroupFields[j] ? (
    //                 <div className="resources-dashboard__cards__list">
    //                     <h3>{resourcesGroupFields[j]}</h3>
    //                     <div key={resourcesGroupFields[j]}>
    //                         {resourcesMap[resourcesGroupFields[j]].map((r) => {
    //                             return (
    //                                 <a key={r.title} href={r.url}>
    //                                     <Card
    //                                         direction={Direction.vertical}
    //                                         className="resources-dashboard__cards__list__item"
    //                                     >
    //                                         <span>{r.platform}</span>
    //                                         <h4>{r.title}</h4>
    //                                     </Card>
    //                                 </a>
    //                             );
    //                         })}
    //                     </div>
    //                 </div>
    //             ) : (
    //                 ''
    //             )
    //         );
    //     }
    //     return renderedItems;
    // };

    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Resources "
                url="resources/"
                description="Collection of resources related with Artificial Intelligence and Machine Learning"
            />

            {/* <PageSection className="resources-dashboard">
                <Container>
                    <div className="resources-dashboard__buttons">
                        <Button
                            accent={Accent.secondary}
                            type={ButtonType.custom}
                            onClickFunction={() =>
                                updateDashboard(
                                    resourcesFilterer,
                                    ResourcesGroupField.topic
                                )
                            }
                            className="resources-dashboard__buttons__btn"
                        >
                            Group by Topic
                        </Button>
                        <Button
                            accent={Accent.secondary}
                            type={ButtonType.custom}
                            onClickFunction={() =>
                                updateDashboard(
                                    resourcesFilterer,
                                    ResourcesGroupField.type
                                )
                            }
                            className="resources-dashboard__buttons__btn"
                        >
                            Group by Type
                        </Button>
                        <Button
                            accent={Accent.secondary}
                            type={ButtonType.custom}
                            onClickFunction={() =>
                                updateDashboard(
                                    resourcesFilterer,
                                    ResourcesGroupField.platform
                                )
                            }
                            className="resources-dashboard__buttons__btn"
                        >
                            Group by Platform
                        </Button>
                    </div>
                    <div className="resources-dashboard__cards">
                        {renderDashboard(resources)}
                    </div>
                </Container>
            </PageSection> */}
            <PageBanner className="page-banner-single-item">
                <Fragment>
                    <h1>Resources</h1>
                </Fragment>
                <Fragment></Fragment>
            </PageBanner>
            <PageSection>
                {postsPerCategory.map((resourceCategory) => {
                    return (
                        <Container key={resourceCategory.title}>
                            <Group size={4}>
                                <Block
                                    direction={Direction.vertical}
                                    className=""
                                >
                                    <h3>{resourceCategory.title} </h3>
                                    <p>{resourceCategory.description}</p>
                                </Block>
                                {posts[resourceCategory.posts[0]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[0]]}
                                    />
                                ) : (
                                    ''
                                )}
                                {posts[resourceCategory.posts[1]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[1]]}
                                    />
                                ) : (
                                    ''
                                )}
                                {posts[resourceCategory.posts[2]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[2]]}
                                    />
                                ) : (
                                    ''
                                )}
                                 <Block
                                    direction={Direction.vertical}
                                    className=""
                                >
                                   
                                </Block>
                                {posts[resourceCategory.posts[3]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[3]]}
                                    />
                                ) : (
                                    ''
                                )}
                                 {posts[resourceCategory.posts[4]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[4]]}
                                    />
                                ) : (
                                    ''
                                )}
                                 {posts[resourceCategory.posts[5]] ? (
                                    <PostCard
                                        className="resource-post"
                                        post={posts[resourceCategory.posts[5]]}
                                    />
                                ) : (
                                    ''
                                )}
                            </Group>
                        </Container>
                    );
                })}
            </PageSection>
        </PageLayout>
    );
};

export const resourceQuery = graphql`
    {
        resourcesYaml {
            resourcesBlogs {
                description
                posts
                title
            }
        }
        allMdx(filter: { frontmatter: { layout: { eq: "BlogPage" } } }) {
            nodes {
                frontmatter {
                    author
                    description
                    image {
                        childImageSharp {
                            fluid {
                                base64
                                aspectRatio
                                src
                                srcSet
                                sizes
                            }
                        }
                    }
                    layout
                    suburl
                    tags
                    title
                }
            }
        }
    }
`;

export default Resources;
